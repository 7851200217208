#shop-grid {
  display: grid;
  grid-template-columns: 1fr;
	grid-gap: 5px;
  img {
    width: 184px;
    height: 184px;
    padding: 25px 0;
	}
	h2 {
	  font-size: 26px;
	}
	.btn-shop {
		display: inline-block;
    margin-top: 25px;
    font-size: 18px;
    padding: 12px;
    cursor: pointer;
	}
	a {
		font-size: 18px;
    padding: 12px;
    border: none;
	}
	p {
    min-height: 100px;
    font-size: 20px;
	}
	.item {
		border: 1px solid #a90b0b;
    padding: 40px;
    background: #881414;
    &:hover {
    	background: #a90b0b;
    }
	}
	@media (min-width: 768px) {
		grid-template-columns: 1fr 1fr;
	}
}
#timestamp {
	color: #d9dfe0;
	font-family: monospace;
}
#shop {
	img {
		position: relative;
		bottom: 100px;
		justify-self: center;
		height: 184px;
		width: 184px;
		grid-column: 1;
		@media (min-width: 1024px) {
			grid-column: 2;
		}
	}
}
#price-tag {
	display: block;
  font-size: 26px;
  font-weight: 700;
  font-family: monospace;
}
#shop-main .btn-shop {
	background: #d8e187;
  border: 1px solid #f3ff83;
	display: inline-block;
	margin-top: 25px;
	font-size: 18px;
	padding: 12px;
	cursor: pointer;
}
// Snipcart overrides
#snipcart {
	text-align: left !important;
}
.snipcart-cart__content {
  margin: 0 auto;
  background-color: #f0f5f6 !important;
}
section.snipcart-empty-cart {
  background: #f0f5f6 !important;
}
.snipcart-cart-header {
	margin-top: 32px !important;
}
#snipcart footer {
  min-height: auto !important;
}
#snipcart header {
  min-height: unset !important;
}
#snipcart section {
  min-height: auto !important;
}
.snipcart-item-description {
  text-align: left !important;
}
.snipcart-cart-summary__content {
  background-color: #fff !important;
}
@media (min-width: 1024px) {
	.snipcart-cart-header {
	  //padding: 128px 16px !important;
	}
}
#shop-main {
	.main-nav {
		position: absolute;
	}
}
.snipcart-modal__container {
	z-index: 99999 !important;
}
a.snipcart-total-price.snipcart-checkout:after {
  content: '\f07a';
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  margin: 0px 0px 0px 10px;
  text-decoration: none;
}
