// embeds
.ytcontainer {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  iframe.yt {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
@media (min-width: 1024px) {
	.yt-resp {
		max-width: 60%;
	}
}
// /videos page
.yt-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 50px;
  @media (min-width: 1024px) {
  	grid-template-columns: 1fr 1fr;
  }
  .video-info {
  	padding: 20px 0;
  }
  h3 {
  	align-self: center;
  }
}
.yt-grid-archive {
	display: grid;
  grid-template-columns: 1fr;
  grid-gap: 50px;
  @media (min-width: 1024px) {
  	grid-template-columns: repeat(5, 1fr);
  }
  img {
  	border: 2px solid #881414;
  }
  .video-info {
  	padding: 20px 0;
  	a {
  		border: none;
  	}
  }
  .thin {
  	font-weight: 100;
  	/*@media (min-width: 1024px) {
  		text-align: left;
  	}*/
  }
  h3 {
  	align-self: center;
		font-size: 1.2em;
  	margin: 10px 0;
  	@media (min-width: 1024px) {
  		font-size: 1em;
  	}
  }
}
