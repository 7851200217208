// Reset

html, body {
	margin: 0;
	padding: 0;
	//overflow-y: scroll;
	box-sizing: border-box;
	a {
	margin: 0; 
	padding: 0; 
	font-size: 100%; 
	vertical-align: baseline; 
	background: transparent;
	text-decoration: none;
	color: #fff;
	}
	h1,h2,h3,h4,h5,h6 {
	margin: 0;
	padding: 0;
	}
	ul,ol {
		list-style: none;
		padding: 0;
		margin: 0;
	}
	img,
	object,
	embed {
	max-width: 100%;
	}
}
